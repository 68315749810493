/* header, body, and footer */

body {
  font-family: Roboto, Helvetica, Arial, sans-serif;
}

/* styles */

.custom-link:hover {
  text-shadow: 3px 3px 3px lightsteelblue;
}

.my-4, .my-5 {
  color: black;
}

.png-shadow {
  filter: drop-shadow(2px 2px 2px darkslategrey);
}

.text-shadow {
  text-shadow: 3px 3px 3px darkslategrey;
}

.blue-background-color {
  background-color: #336699;
}

.lightblue-background-color {
  background-color: lightsteelblue;
}

.cursive-font {
  font-family: "Butterfly Kids";
}

.artist-font {
  font-size: 50px;
  letter-spacing: 0.1em;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 3px;
  -webkit-text-stroke-color: white;
  text-shadow: 4px 4px darkblue, 10px 10px #000000;
}

.large-margin-top {
  margin-top: 102px;
}

.large-margin-top2 {
  margin-top: 102px;
}

/* animations */

.logo-animation {
  color: white;
  animation: neon 3s infinite;
}

:root {
  --shadow-color: darkblue;
  --shadow-color-light: white;
}

@keyframes neon {
  0% {
    text-shadow: -1px -1px 1px var(--shadow-color-light),
      -1px 1px 1px var(--shadow-color-light),
      1px -1px 1px var(--shadow-color-light),
      1px 1px 1px var(--shadow-color-light), 0 0 3px var(--shadow-color-light),
      0 0 10px var(--shadow-color-light), 0 0 20px var(--shadow-color-light),
      0 0 30px var(--shadow-color), 0 0 40px var(--shadow-color),
      0 0 50px var(--shadow-color), 0 0 70px var(--shadow-color),
      0 0 100px var(--shadow-color), 0 0 200px var(--shadow-color);
  }
  50% {
    text-shadow: -1px -1px 1px var(--shadow-color-light),
      -1px 1px 1px var(--shadow-color-light),
      1px -1px 1px var(--shadow-color-light),
      1px 1px 1px var(--shadow-color-light), 0 0 5px var(--shadow-color-light),
      0 0 15px var(--shadow-color-light), 0 0 25px var(--shadow-color-light),
      0 0 40px var(--shadow-color), 0 0 50px var(--shadow-color),
      0 0 60px var(--shadow-color), 0 0 80px var(--shadow-color),
      0 0 110px var(--shadow-color), 0 0 210px var(--shadow-color);
  }
  100% {
    text-shadow: -1px -1px 1px var(--shadow-color-light),
      -1px 1px 1px var(--shadow-color-light),
      1px -1px 1px var(--shadow-color-light),
      1px 1px 1px var(--shadow-color-light), 0 0 3px var(--shadow-color-light),
      0 0 10px var(--shadow-color-light), 0 0 20px var(--shadow-color-light),
      0 0 30px var(--shadow-color), 0 0 40px var(--shadow-color),
      0 0 50px var(--shadow-color), 0 0 70px var(--shadow-color),
      0 0 100px var(--shadow-color), 0 0 200px var(--shadow-color);
  }
}

.link-animation {
  color: darkblue;
  letter-spacing: 0.1em;
  transition: 0.3s;
  text-shadow: 1px 1px 0 #a0bed9, 1px 2px 0 #a0bed9, 1px 3px 0 #a0bed9,
    1px 4px 0 #a0bed9, 1px 5px 0 #a0bed9, 1px 6px 0 #a0bed9, 1px 7px 0 #a0bed9,
    1px 8px 0 #a0bed9, 5px 13px 15px black;
}

.link-animation:hover {
  transition: 0.3s;
  transform: scale(1.1);
  text-shadow: 1px -1px 0 #a0bed9, 1px -2px 0 #a0bed9, 1px -3px 0 #a0bed9,
    1px -4px 0 #a0bed9, 1px -5px 0 #a0bed9, 1px -6px 0 #a0bed9,
    1px -7px 0 #a0bed9, 1px -8px 0 #a0bed9, 5px -13px 15px black,
    5px -13px 25px #808080;
}

/* media queries */

@media screen and (max-width: 991px) {
  .large-margin-top {
    margin-top: 148px;
  }
}
